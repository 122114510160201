$primaryOverride: #994631;
@use '~bulma/sass' with (
  $primary: $primaryOverride
);

@import '~bulma/sass/utilities';

$tomato-picture-border-radius: 1.2rem;

.box {
  border: 1px solid $white-ter;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.is-rounded-edge {
  border-radius: $tomato-picture-border-radius;
  border: 1px solid $white-ter;
}

.picture-description {
  position: absolute;
  bottom: 0px;
  backdrop-filter: blur(10px);
  border-radius: 0 0 $tomato-picture-border-radius $tomato-picture-border-radius;
  background-color: rgb(255 255 255 / 30%);
  max-height: 40%;
  width: 100%;
}

.is-zoomed {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000;
  width: 100% !important;
  margin: 0 !important;
  height: 100%;
  background-color: $black-ter;
  overflow: auto;
}

.is-zoomed > figure {
  max-width: 1000px !important;
  margin: 1.5rem auto;
}

html {
  overflow: auto;
}

.cursor-zoom-out {
  cursor: zoom-out;
}

.waiting-overlay {
  position: absolute;
  width: 100%;
  backdrop-filter: blur(2px);
  height: 100%;
  border-radius: $tomato-picture-border-radius;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 1;
  text-align: center;
  border: 1px solid $white-ter;
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.height-chart .apexcharts-xaxis-texts-g text,
.height-chart .apexcharts-yaxis-texts-g text {
  fill: $primary;
  color: $primary;
}

.pointer {
  cursor: pointer;
}
